import React from 'react';

interface IconProps {
  size?: number;
  color?: string;
}

const RightArrow: React.FC<IconProps> = ({
  size = 24,
  color = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={color}
  >
    <path d="M9 6l6 6-6 6" stroke={color} strokeWidth="2" fill="none" />
  </svg>
);

export default RightArrow;
