import { createRoot } from 'react-dom/client';
import './index.scss';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'; // Import react-query components
//Screens
import { BranchCalendarScreen } from './screens/branch-calendar';
import { NotFoundScreen } from './components/not-found-screen/not-found-screen';
import { LoadingProvider } from './components/loading-screen/loading-context';
import GlobalLoadingScreen from './components/loading-screen/loading-screen';

// Localization
import { LocalizationProvider } from './context/LocalizationContext';

// Create a QueryClient instance
const queryClient = new QueryClient();
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<BranchCalendarScreen />} />
      <Route path="*" element={<NotFoundScreen />} />
    </>,
  ),
);

createRoot(document.getElementById('root')!).render(
  <LocalizationProvider>
    {' '}
    {/* Wrap with LocalizationProvider */}
    <LoadingProvider>
      {' '}
      {/* Wrap with LoadingProvider */}
      <QueryClientProvider client={queryClient}>
        {' '}
        {/* React Query Provider */}
        <GlobalLoadingScreen /> {/* Global loading screen */}
        <RouterProvider router={router} /> {/* React Router */}
      </QueryClientProvider>
    </LoadingProvider>
  </LocalizationProvider>,
);
