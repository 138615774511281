import './calendar.scss'; // Import the CSS file
import { WeekdayNumbers, DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { CalendarCell } from './calendar-cell';
import LeftArrow from '../icons/left-arrow'; // Import the LeftArrow component
import RightArrow from '../icons/right-arrow'; // Import the RightArrow component
import { AvailabilityDate } from '../../types';

interface CalendarProps {
  selectedDate?: DateTime; // The currently selected date
  onSelectDate: (date: DateTime) => void; // Callback to parent when a date is selected
  availableDays: AvailabilityDate[];
}

export const Calendar = (p: CalendarProps) => {
  const { onSelectDate, selectedDate, availableDays } = p;
  const [currentMonth, setCurrentMonth] = useState(DateTime.now()); // State for the current month being viewed
  const rows = useCalendarRows(currentMonth); // Get the rows (weeks) of days for the current month

  const isAvailable = (date: DateTime) => {
    const isFromCurrentMonth =
      date.month === currentMonth.month && date.year === currentMonth.year;

    return (
      isFromCurrentMonth &&
      availableDays.some((availableDay) => {
        const availableDayDateTime = DateTime.fromMillis(availableDay.day);
        return availableDayDateTime.hasSame(date, 'day');
      })
    );
  };

  // Function to check if the left arrow should be disabled
  const isPreviousMonthDisabled = () => {
    const startOfCurrentMonth = DateTime.now().startOf('month');
    return currentMonth < startOfCurrentMonth; // Compare full DateTime object
  };

  // Function to check if the right arrow should be disabled
  const isNextMonthDisabled = () => {
    const endOfAllowedYear = DateTime.now().plus({ year: 1 }).endOf('year');
    return currentMonth.endOf('month') >= endOfAllowedYear;
  };

  return (
    <>
      <div className="calendar-container">
        {/* Month navigation */}
        <div className="calendar-month-year-header">
          <div
            className={`calendar-month-arrow ${
              isPreviousMonthDisabled() ? 'disabled' : ''
            }`}
            onClick={() => {
              if (!isPreviousMonthDisabled()) {
                setCurrentMonth((x) => x.minus({ months: 1 }));
              }
            }}
          >
            <LeftArrow size={24} color="#3173ba" />
          </div>
          <div className="calendar-month-label">
            <span>{currentMonth.monthLong + ' ' + currentMonth.year}</span>
          </div>
          <div
            className={`calendar-month-arrow ${
              isNextMonthDisabled() ? 'disabled' : ''
            }`}
            onClick={() => {
              if (!isNextMonthDisabled()) {
                setCurrentMonth((x) => x.plus({ months: 1 }));
              }
            }}
          >
            <RightArrow size={24} color="#3173ba" />
          </div>
        </div>

        {/* Days of the week header */}
        <CalendarHeaderRow />

        {/* Calendar rows */}
        {rows.map((row, rowIndex) => {
          return (
            <div className="calendar-date-row" key={rowIndex}>
              {row.map((cell) => {
                const available = isAvailable(cell);

                return (
                  <CalendarCell
                    key={cell.toISO()} // Unique key for each cell
                    date={cell}
                    isSelected={selectedDate?.hasSame(cell, 'day') || false} // Check if this cell is the selected date
                    isAvailable={available} // Pass the availability to the CalendarCell
                    onClick={available ? () => onSelectDate(cell) : undefined} // Only clickable if available
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

// Utility function to generate calendar rows for a given month
const useCalendarRows = (monthAndYear: DateTime) => {
  return useMemo(() => {
    const firstDayOfMonth = monthAndYear.startOf('month');
    const lastDayOfMonth = monthAndYear.endOf('month');

    // Adjust the first day to the nearest Sunday and the last day to the nearest Saturday
    const firstDayCalendar =
      firstDayOfMonth.weekday === 7
        ? firstDayOfMonth
        : firstDayOfMonth.minus({ days: firstDayOfMonth.weekday });

    const lastDayCalendar =
      lastDayOfMonth.weekday === 6
        ? lastDayOfMonth
        : lastDayOfMonth.plus({ days: 6 - lastDayOfMonth.weekday });

    const totalDays = lastDayCalendar.diff(firstDayCalendar, 'days').days + 1;

    // Create a 2D array (weeks of days)
    return Array.from({ length: totalDays }, (_, index) => {
      return firstDayCalendar.plus({ days: index });
    }).reduce(
      (weeks, day, index) => {
        if (index % 7 === 0) weeks.push([]);
        weeks[weeks.length - 1].push(day);
        return weeks;
      },
      [] as Array<DateTime[]>,
    );
  }, [monthAndYear]);
};

// Renders the days of the week (Sunday to Saturday)
const CalendarHeaderRow = () => {
  return (
    <div className="calendar-header-row">
      {getDaysOfWeek().map((d, index) => (
        <div className="calendar-header-day" key={index}>
          {d}
        </div>
      ))}
    </div>
  );
};

// Utility to get day names
const getDaysOfWeek = () => {
  return Array.from({ length: 7 }, (_, i) =>
    DateTime.now()
      .set({ weekday: i as WeekdayNumbers })
      .toLocaleString({
        weekday: 'short',
      }),
  );
};
