import { useQuery } from '@tanstack/react-query';
import { config } from '../config/config';
import { GetAvailabilityResponse } from '../types';
import { BranchId } from '@yes/common-js';

export const useAvailability = (branchId: BranchId) => {
  const { data, isLoading, error } = useQuery<
    GetAvailabilityResponse | undefined
  >({
    queryKey: ['schedules', branchId],
    queryFn: async ({ signal }) => {
      const response = await fetch(config.FETCH_UPCOMING_APPOINTMENTS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          branchId,
        }),
        signal,
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.message ||
            'An unknown error occurred while fetching schedules',
        );
      }

      const schedules = await response.json();
      return schedules;
    },
    enabled: !!branchId,
    staleTime: 60000, // Cache data for 1 minute (adjust as needed)
    refetchOnWindowFocus: false, // Disable refetching when switching back to the tab
    refetchOnReconnect: false, // Disable refetching when reconnecting to the internet
    retry: false,
  });
  return { data, isLoading, error };
};
