import React from 'react';
import { DateTime } from 'luxon';

interface CalendarCellProps {
  date: DateTime;
  isSelected: boolean;
  isAvailable: boolean;
  onClick?: () => void;
}

export const CalendarCell = ({
  date,
  isSelected,
  isAvailable,
  onClick,
}: CalendarCellProps) => {
  const cellClass = `calendar-cell ${isSelected ? 'selected' : ''} ${!isAvailable ? 'unavailable' : 'available'}`;

  return (
    <div
      className={cellClass} // Add "selected" class if the cell is selected
      onClick={isAvailable ? onClick : undefined} // Handle click events
    >
      {date.day}
    </div>
  );
};
