import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import {
  getTranslation,
  loadTranslations,
  getBrowserLocale,
  defaultLocale,
} from './localizationService';
import { LocalizationKeys, Translations } from '../types/localization';

interface LocalizationContextType {
  t: (key: LocalizationKeys) => string;
  locale: string;
  changeLocale: (newLocale: string) => void;
}

const LocalizationContext = createContext<LocalizationContextType | undefined>(
  undefined,
);

interface LocalizationProviderProps {
  children: ReactNode;
}

export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({
  children,
}) => {
  const [locale, setLocale] = useState<string>(getBrowserLocale());
  console.log('local', locale);
  const [translations, setTranslations] = useState<Translations>({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(locale);
      setTranslations(loadedTranslations);
    };
    fetchTranslations();
  }, [locale]);

  const t = (key: LocalizationKeys): string =>
    getTranslation(translations, key as string);

  const changeLocale = async (newLocale: string): Promise<void> => {
    const loadedTranslations = await loadTranslations(newLocale);
    setLocale(newLocale);
    setTranslations(loadedTranslations);
    localStorage.setItem('locale', newLocale);
  };

  return (
    <LocalizationContext.Provider value={{ t, locale, changeLocale }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = (): LocalizationContextType => {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error(
      'useLocalization must be used within a LocalizationProvider',
    );
  }
  return context;
};
